import http from '../lib/http-common';

let API_URL = process.env.REACT_APP_BACKEND_URL + '/api/v2/';

const getKeywords = () => {
    return http.get(API_URL + 'keywords')
}

const getSimpleKeywords = () => {
    return http.get(API_URL + 'keywords-simple')
}

const getPackageDetailsAndKeywords = (packageId) => {
    return http.get(API_URL + 'keywords-and-package/' + packageId + '/details')
}

const getKeywordsByLocation = (formData) => {
    return http.post(API_URL + 'keywords-location', formData)
}

const getCategories = () => {
    return http.get(API_URL + 'categories')
}

const getSubcategories = (catId) => {
    return http.get(API_URL + 'subcategories/' + catId)
}

const getProductSubcategories = (catId) => {
    return http.get(API_URL + 'product/subcategories/' + catId)
}

const getCountries = () => {
    return http.get(API_URL + 'countries')
}

const getCities = () => {
    return http.get(API_URL + 'cities')
}

const getReviewReportReasons = () => {
    return http.get(API_URL + 'review-report-reasons')
}

const getFacilities = () => {
    return http.get(API_URL + 'facilities')
}

const getAdPackages = () => {
    return http.get(API_URL + 'ad-packages')
}

const getPackageDetails = (package_id) => {
    return http.get(API_URL + 'package/' + package_id + '/details')
}

const getPaymentGateways = () => {
    return http.get(API_URL + 'payment-gateways')
}

const getSupportQuestions = () => {
    return http.get(API_URL + 'support-questions')
}

const commonService = {
    getKeywords,
    getSimpleKeywords,
    getPackageDetailsAndKeywords,
    getKeywordsByLocation,
    getCategories,
    getSubcategories,
    getProductSubcategories,
    getCountries,
    getCities,
    getReviewReportReasons,
    getFacilities,
    getAdPackages,
    getPackageDetails,
    getPaymentGateways,
    getSupportQuestions
};

export default commonService;