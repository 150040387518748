import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { setMessage } from "./message";
//import { setError } from "./errors";

import commonService from "../../services/commonService";

export const getKeywords = createAsyncThunk(
    "keywords/getKeywords",
    async () => {
        const response = await commonService.getKeywords();
        return response.data.data;
    }
);

export const getKeywordsByLocation = createAsyncThunk(
    "keywords/getKeywordsByLocation",
    async (formData) => {
        const response = await commonService.getKeywordsByLocation(formData);
        return response.data.data;
    }
);

const initialState = {
    loading: false,
    keywords: '',
    categories: '',
    locations: ''
};

const keywordsSlice = createSlice({
    name: "keywords",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getKeywords.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getKeywords.fulfilled, (state, action) => {
            state.loading = false;
            state.keywords = action.payload.keywords;
            state.categories = action.payload.categories;
            state.locations = action.payload.locations;
        });
        builder.addCase(getKeywords.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getKeywordsByLocation.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getKeywordsByLocation.fulfilled, (state, action) => {
            state.loading = false;
            state.keywords = action.payload.keywords;
            state.categories = action.payload.categories;
            state.locations = action.payload.locations;
        });
        builder.addCase(getKeywordsByLocation.rejected, (state, action) => {
            state.loading = false;
        });
    }
});

const { reducer } = keywordsSlice;
export default reducer;