import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { setMessage } from "./message";
//import { setError } from "./errors";

import userService from "../../services/userService";
import ownerService from "../../services/ownerService";

const initialPaginationState = {
    currentPage: 0,
    totalPages: 1,
    totalItems: 0,
    hasMore: false
};

export const getInboxUsers = createAsyncThunk(
    "chat/getInboxUsers",
    async () => {
        const response = await userService.getInbox();
        return response.data.data;
    }
);

export const getInboxOwners = createAsyncThunk(
    "chat/getInboxOwners",
    async () => {
        const response = await ownerService.getInbox();
        return response.data.data;
    }
);

export const getInboxUserMessages = createAsyncThunk(
    "chat/getInboxUserMessages",
    async (data) => {
        // const {convId, participantId, isOwner} = data;
        const response = await userService.getInboxUserMessages(data.convId, data.participantId, data.isOwner);
        return response.data.data;
    }
);

export const loadMoreUserMessages = createAsyncThunk(
    "chat/loadMoreUserMessages",
    async (data, { getState }) => {
        const currentPage = getState().chat.pagination.currentPage;
        // const {convId, participantId, isOwner} = data;
        const response = await userService.loadMoreUserMessages(data.convId, data.participantId, data.isOwner, currentPage);
        return response.data.data;
    }
);

export const getInboxUserMessagesByUsername = createAsyncThunk(
    "chat/getInboxUserMessagesByUsername",
    async(data) => {
        const response = await userService.getInboxMessagesByUsername(data.username, data.usertype);
        return response.data.data;
    }
);

export const getInboxOwnerMessages = createAsyncThunk(
    "chat/getInboxOwnerMessages",
    async (data) => {
        const {convId, participantId} = data;
        const response = await ownerService.getInboxOwnerMessages(convId, participantId);
        return response.data.data;
    }
);

export const loadMoreOwnerMessages = createAsyncThunk(
    "chat/loadMoreOwnerMessage",
    async (data, { getState }) => {
        const currentPage = getState().chat.pagination.currentPage;
        // const {convId, participantId, isOwner} = data;
        const response = await ownerService.loadMoreOwnerMessages(data.convId, data.participantId, currentPage);
        return response.data.data;
    }
);

export const storeInboxUserReply = createAsyncThunk(
    "chat/storeInboxUserReply",
    async (formData) => {
        const response = await userService.storeInboxUserReply(formData);
        return response.data.data;
    }
);

export const storeInboxOwnerReply = createAsyncThunk(
    "chat/storeInboxOwnerReply",
    async (formData) => {
        const response = await ownerService.storeInboxOwnerReply(formData);
        return response.data.data;
    }
);

const initialState = {
    loading: false,
    inboxConvId: '',
    inboxParticipantId: '',
    inboxIsOwner: false,
    inboxUsers: '',
    inboxMessages: '',
    inboxListingTitle: '',
    isBlocked: false,
    isMessageAllow: 1,
    unreadMsgs: '',
    pagination: initialPaginationState,
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getInboxUsers.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getInboxUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.inboxUsers = action.payload.users;
        });
        builder.addCase(getInboxUsers.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getInboxOwners.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getInboxOwners.fulfilled, (state, action) => {
            state.loading = false;
            state.inboxUsers = action.payload.users;
        });
        builder.addCase(getInboxOwners.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getInboxUserMessages.pending, (state, action) => {
            state.loading = true;
            state.inboxMessages = "";
            state.inboxConvId = "";
            state.inboxParticipantId = "";
            state.pagination.currentPage = 0;
            state.pagination.totalItems = 0;
            state.pagination.totalPages = 1;
            state.pagination.hasMore = false;
        });
        builder.addCase(getInboxUserMessages.fulfilled, (state, action) => {
            state.loading = false;
            state.inboxUsers = action.payload.users;
            state.inboxMessages = action.payload.messages;
            state.inboxConvId = action.payload.conversationId;
            state.inboxParticipantId = action.payload.participantId;
            state.inboxIsOwner = action.payload.isOwner;
            state.pagination.currentPage = action.payload.pagination.current_page;
            state.pagination.totalItems = action.payload.pagination.total;
            state.pagination.totalPages = Math.ceil(action.payload.pagination.total / action.payload.pagination.per_page);
            state.pagination.hasMore = action.payload.pagination.last_page > action.payload.pagination.current_page ? true : false;
            state.inboxListingTitle = action.payload.listingTitle;
            state.unreadMsgs = action.payload.unreadMsgs;
        //    state.inboxConvId = action.payload.conversationId;
            state.isBlocked = action.payload.isBlocked;
            state.isMessageAllow = action.payload.isMessageAllow;
        });
        builder.addCase(getInboxUserMessages.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(loadMoreUserMessages.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(loadMoreUserMessages.fulfilled, (state, action) => {
            state.loading = false;
            state.inboxUsers = action.payload.users;
            state.inboxMessages = action.payload.messages;
            state.inboxConvId = action.payload.conversationId;
            state.inboxParticipantId = action.payload.participantId;
            state.inboxIsOwner = action.payload.isOwner;
            state.pagination.currentPage = action.payload.pagination.current_page;
            state.pagination.totalItems = action.payload.pagination.total;
            state.pagination.totalPages = Math.ceil(action.payload.pagination.total / action.payload.pagination.per_page);
            state.pagination.hasMore = action.payload.pagination.last_page > action.payload.pagination.current_page ? true : false;
            state.inboxListingTitle = action.payload.listingTitle;
            state.unreadMsgs = action.payload.unreadMsgs;
            state.isBlocked = action.payload.isBlocked;
            state.isMessageAllow = action.payload.isMessageAllow;
        });
        builder.addCase(loadMoreUserMessages.rejected, (state, action) => {
            state.loading = false;
        });


        builder.addCase(getInboxUserMessagesByUsername.pending, (state, action) => {
            state.loading = true;
            state.inboxMessages = "";
            state.inboxConvId = "";
            state.inboxParticipantId = "";
            state.pagination.currentPage = 0;
            state.pagination.totalItems = 0;
            state.pagination.totalPages = 1;
            state.pagination.hasMore = false;
        });
        builder.addCase(getInboxUserMessagesByUsername.fulfilled, (state, action) => {
            state.loading = false;
            state.inboxUsers = action.payload.users;
            state.inboxMessages = action.payload.messages;
            state.inboxConvId = action.payload.conversationId;
            state.inboxParticipantId = action.payload.participantId;
            state.inboxIsOwner = action.payload.isOwner;
            state.pagination.currentPage = action.payload.pagination.current_page;
            state.pagination.totalItems = action.payload.pagination.total;
            state.pagination.totalPages = Math.ceil(action.payload.pagination.total / action.payload.pagination.per_page);
            state.pagination.hasMore = action.payload.pagination.last_page > action.payload.pagination.current_page ? true : false;
            state.inboxListingTitle = action.payload.listingTitle;
            state.unreadMsgs = action.payload.unreadMsgs;
        //    state.inboxConvId = action.payload.conversationId;
            state.isBlocked = action.payload.isBlocked;
            state.isMessageAllow = action.payload.isMessageAllow;
        });
        builder.addCase(getInboxUserMessagesByUsername.rejected, (state, action) => {
            state.loading = false;
        });

        
        builder.addCase(getInboxOwnerMessages.pending, (state, action) => {
            state.loading = true;
            state.inboxMessages = "";
            state.inboxConvId = "";
            state.inboxParticipantId = "";
            state.pagination.currentPage = 0;
            state.pagination.totalItems = 0;
            state.pagination.totalPages = 1;
            state.pagination.hasMore = false;
        });
        builder.addCase(getInboxOwnerMessages.fulfilled, (state, action) => {
            state.loading = false;
            state.inboxUsers = action.payload.users;
            state.inboxMessages = action.payload.messages;
            state.inboxConvId = action.payload.conversationId;
            state.inboxParticipantId = action.payload.participantId;
            state.inboxIsOwner = action.payload.isOwner;
            state.pagination.currentPage = action.payload.pagination.current_page;
            state.pagination.totalItems = action.payload.pagination.total;
            state.pagination.totalPages = Math.ceil(action.payload.pagination.total / action.payload.pagination.per_page);
            state.pagination.hasMore = action.payload.pagination.last_page > action.payload.pagination.current_page ? true : false;
            state.inboxListingTitle = action.payload.listingTitle;
            state.unreadMsgs = action.payload.unreadMsgs;
            state.isMessageAllow = action.payload.isMessageAllow;
        });
        builder.addCase(getInboxOwnerMessages.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(loadMoreOwnerMessages.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(loadMoreOwnerMessages.fulfilled, (state, action) => {
            state.loading = false;
            state.inboxUsers = action.payload.users;
            state.inboxMessages = action.payload.messages;
            state.inboxConvId = action.payload.conversationId;
            state.inboxParticipantId = action.payload.participantId;
            state.inboxIsOwner = action.payload.isOwner;
            state.pagination.currentPage = action.payload.pagination.current_page;
            state.pagination.totalItems = action.payload.pagination.total;
            state.pagination.totalPages = Math.ceil(action.payload.pagination.total / action.payload.pagination.per_page);
            state.pagination.hasMore = action.payload.pagination.last_page > action.payload.pagination.current_page ? true : false;
            state.inboxListingTitle = action.payload.listingTitle;
            state.unreadMsgs = action.payload.unreadMsgs;
            state.isMessageAllow = action.payload.isMessageAllow;
        });
        builder.addCase(loadMoreOwnerMessages.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(storeInboxUserReply.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(storeInboxUserReply.fulfilled, (state, action) => {
            state.loading = false;
            state.inboxUsers = action.payload.users;
         //   state.inboxMessages = action.payload.messages;
            state.inboxMessages = action.payload.recentMessage;
        });
        builder.addCase(storeInboxUserReply.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(storeInboxOwnerReply.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(storeInboxOwnerReply.fulfilled, (state, action) => {
            state.loading = false;
            state.inboxUsers = action.payload.users;
         //   state.inboxMessages = action.payload.messages;
            state.inboxMessages = action.payload.recentMessage;
        });
        builder.addCase(storeInboxOwnerReply.rejected, (state, action) => {
            state.loading = false;
        });
    }
});

const { reducer } = chatSlice;
export default reducer;