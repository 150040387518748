import React from "react";
import Lottie from "react-lottie";

import loaderAnimation from "../../assets/lottie/loading.json";

const HEIGHT = 200;
const WIDTH = 200;

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const LottieLoader = ({ height, width, style, ...props }) => {
    const css = {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };

    return (
        <Lottie
            options={defaultOptions}
            height={HEIGHT}
            width={WIDTH}
            style={{ ...css, ...style }}
        />
    );
};

export default LottieLoader;
