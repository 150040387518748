import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setError } from "./errors";

import AuthService from "../../services/authService";
const accessToken = JSON.parse(localStorage.getItem("token"));

export const register = createAsyncThunk(
    "auth/register",
    async (formData, thunkAPI) => {
        try {
            const response = await AuthService.register(formData);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            const errorValidation =
                error.response &&
                error.response.data &&
                error.response.data.errors;
            thunkAPI.dispatch(setError(errorValidation));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async (formData, thunkAPI) => {
        try {
            const response = await AuthService.login(formData);
            return { token: response.data.accessToken };
        } catch (error) {
            const message = error.response.data.message;
            if(message) {
                thunkAPI.dispatch(setMessage(message));    
            }
            const errorValidation = error.response.data.errors;
            if(errorValidation) {
                thunkAPI.dispatch(setError(errorValidation));
            }

            return thunkAPI.rejectWithValue();
        }
    }
);

export const loginSocial = createAsyncThunk(
    "auth/loginSocial",
    async (formData, thunkAPI) => {
        try {
            const response = await AuthService.userSocialLogin(formData);
            return { token: response.data.accessToken };
        } catch (error) {
            const message = error.response.data.message;
            if(message) {
                thunkAPI.dispatch(setMessage(message));    
            }
            const errorValidation = error.response.data.errors;
            if(errorValidation) {
                thunkAPI.dispatch(setError(errorValidation));
            }

            return thunkAPI.rejectWithValue();
        }
    }
);

export const verifyCode = createAsyncThunk(
    "auth/verifyCode",
    async (formData, thunkAPI) => {
        try {
            const response = await AuthService.verifyCode(formData);
            return response.data;
        } catch (error) {
            const message = error.response.data.message;
            if(message) {
                thunkAPI.dispatch(setMessage(message));    
            }
            const errorValidation = error.response.data.errors;
            if(errorValidation) {
                thunkAPI.dispatch(setError(errorValidation));
            }

            return thunkAPI.rejectWithValue();
        }
    } 
);

export const logout = createAsyncThunk(
    "auth/logout", 
    async () => {
        await AuthService.logout();
    }
);

const initialState = {
    loading: false,
    error: null,
    isLoggedIn: accessToken ? true : false,
    token: accessToken ? accessToken : null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(register.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(register.fulfilled, (state, action) => {
            state.loading = false;
            state.isLoggedIn = true;
            state.token = action.payload.accessToken;
        });
        builder.addCase(register.rejected, (state, action) => {
            state.loading = false;
            state.isLoggedIn = false;
            state.token = null;
        });
        builder.addCase(login.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
            state.isLoggedIn = true;
            state.token = action.payload.accessToken;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.isLoggedIn = false;
            state.token = null;
        });
        builder.addCase(loginSocial.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(loginSocial.fulfilled, (state, action) => {
            state.loading = false;
            state.isLoggedIn = true;
            state.token = action.payload.accessToken;
        });
        builder.addCase(loginSocial.rejected, (state, action) => {
            state.loading = false;
            state.isLoggedIn = false;
            state.token = null;
        });
        builder.addCase(verifyCode.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(verifyCode.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload.user;
        });
        builder.addCase(verifyCode.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(logout.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(logout.fulfilled, (state, action) => {
            state.loading = false;
            state.isLoggedIn = false;
            state.user = null;
        });
    },
});

const { reducer } = authSlice;
export default reducer;