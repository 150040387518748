import http from '../lib/http-common';
import authBizHeader from "./authBizHeader";

let API_URL = process.env.REACT_APP_BACKEND_URL + '/api/v2/biz/owner/';

const getOwnerHeaderData = () => {
   return http.get(API_URL + "header-data", { headers: authBizHeader() });
}

const getNotifications = (page) => {
   return http.get(API_URL + "notifications", 
       { 
           headers: authBizHeader(),
           params: {
               page: page + 1
           },
       }
   );
}

const markNotificationsAllRead = () => {
   return http.get(API_URL + "notification/read", { headers: authBizHeader() });
}

const getDashboardData = () => {
   return http.get(API_URL + "dashboard", { headers: authBizHeader() });
}

const getListingChartData = (formData) => {
   return http.post(API_URL + "listing/chart-data", formData, { headers: authBizHeader() });
}

const getListings = (page) => {
   return http.get(API_URL + "listings", 
        { 
            headers: authBizHeader(),
            params: {
               page: page
            }, 
        }
   );
}

const getClaims = (page) => {
   return http.get(API_URL + "claims", 
      { 
          headers: authBizHeader(),
          params: {
             page: page
          }, 
      }
 );
}

const updateListingStatus = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/status", formData, { headers: authBizHeader() });
}

const getCampaignCreateData = (listId) => {
   return http.get(API_URL + "campaign-data",
      {
         headers: authBizHeader(),
         params: {
            listing_id: listId
         }
      }
   );
}

const getOwnerListingsForAdCampaign = (page) => {
   return http.get(API_URL + "listings/campaign", 
        { 
            headers: authBizHeader(),
            params: {
                page: page
            }, 
        }
   );
}

const getListingReviews = (listId, page) => {
   return http.get(API_URL + "listing/" + listId + "/reviews", 
         { 
            headers: authBizHeader(),
            params: {
               page: page + 1
            }   
         }
      );
}

const getReview = (reviewId) => {
   return http.get(API_URL + "review/" + reviewId, { headers: authBizHeader() });
}

const storeReviewThanksReply = (formData) => {
   return http.post(API_URL + "review/thanks-reply", formData, { headers: authBizHeader() });
}

const storeReviewOtherReply = (formData) => {
   return http.post(API_URL + "review/other-reply", formData, { headers: authBizHeader() });
}

const storeReviewReport = (formData) => {
   return http.post(API_URL + "review/report", formData, { headers: authBizHeader() });
}

const searchListing = (formData, page) => {
   return http.post(API_URL + "listing/search", formData, 
         { 
            headers: authBizHeader(),
            params: {
               page: page
            }
         }
      );
}

const getClaimByListingId = (listId) => {
   return http.get(API_URL + "listing/" + listId + "/claim", { headers: authBizHeader() });
}

const storeClaim = (formData) => {
   return http.post(API_URL + "claim", formData, { headers: authBizHeader() });
}

const storeListing = (formData) => {
   return http.post(API_URL + "listing", formData, { headers: authBizHeader() });
}

const getListingConfirmation = (listId) => {
   return http.get(API_URL + "listing/" + listId + "/confirmation", { headers: authBizHeader() });
}

const getListingEdit = (listId) => {
   return http.get(API_URL + "listing/" + listId + "/edit", { headers: authBizHeader() });
}

const updateListingBasicInfo = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/basic", formData, { headers: authBizHeader() });
}

const updateListingCategory = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/category", formData, { headers: authBizHeader() });
}

const updateListingFeatures = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/features", formData, { headers: authBizHeader() });
}

const updateListingOfficeHours = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/office-hours", formData, { headers: authBizHeader() });
}

const updateListingPromoVideo = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/promo-video", formData, { headers: authBizHeader() });
}

const updateListingSpecialties = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/specialties", formData, { headers: authBizHeader() });
}

const updateListingDescription = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/description", formData, { headers: authBizHeader() });
}

const updateListingHistory = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/history", formData, { headers: authBizHeader() });
}

const updateListingAddress = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/address", formData, { headers: authBizHeader() });
}

const updateListingSocialLinks = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/social-links", formData, { headers: authBizHeader() });
}

const updateListingEventsTimming = (formData, listId) => {
   return http.post(API_URL + "listing/" + listId + "/events", formData, { headers: authBizHeader() });
}

const deleteSocialMediaLink = (socId) => {
   return http.delete(API_URL + "listing/social-link/" + socId, { headers: authBizHeader() });
}

const getListingCoverImages = (listId) => {
   return http.get(API_URL + "listing/" + listId + "/cover-images", { headers: authBizHeader() });
}

const uploadListingCoverImages = (formData) => {
   return http.post(API_URL + "listing/cover-images", formData, { headers: authBizHeader() });
}

const deleteListingCoverImage = (imageId) => {
   return http.delete(API_URL + "listing/cover-image/" + imageId, { headers: authBizHeader() });
}

const getListingGallery = (listId, page) => {
   return http.get(API_URL + "listing/" + listId + "/gallery", 
         {  
            headers: authBizHeader(),
            params: {
               page: page + 1
            }
         }
      );
}

const storeListingGalleryPhotos = (formData) => {
   return http.post(API_URL + "listing/gallery", formData, { headers: authBizHeader() });
}

const reportGalleryPhoto = (formData) => {
   return http.post(API_URL + "listing/gallery/photo-report", formData, { headers: authBizHeader() });
}

const getListingLogo = (listId) => {
   return http.get(API_URL + "listing/" + listId + "/logo", { headers: authBizHeader() });
}

const uploadListingLogo = (formData) => {
   return http.post(API_URL + "listing/logo", formData, { headers: authBizHeader() });
}

const deleteListingLogo = (listId) => {
   return http.delete(API_URL + "listing/logo/" + listId, { headers: authBizHeader() });
}

const getListingStats = (listId) => {
   return http.get(API_URL + "listing/" + listId + "/stats", { headers: authBizHeader() });
}

const getOwnerCampaigns = (page) => {
   return http.get(API_URL + "campaigns", 
      { 
         headers: authBizHeader(),
         params: {
            page: page
         },
      }
   );
}

const storeAdCampaign = (formData) => {
   return http.post(API_URL + "campaign", formData, { headers: authBizHeader() });
}

const getCampaignEdit = (campId) => {
   return http.get(API_URL + "campaign/" + campId + "/edit", { headers: authBizHeader() });
}

const updateAdCampaign = (formData, campId) => {
   return http.post(API_URL + "campaign/" + campId, formData, { headers: authBizHeader() });
}

const getCampaignConfirmation = (campId) => {
   return http.get(API_URL + "campaign/" + campId + "/confirmation", { headers: authBizHeader() });
}

const deleteCampaign = (campId) => {
   return http.delete(API_URL + "campaign/" + campId, { headers: authBizHeader() });
}

const getCampaignForPreview = (campId) => {
   return http.get(API_URL + "campaign/" + campId + "/preview", { headers: authBizHeader() });
}

const ListingDiscountCouponCreate = (listId) => {
   return http.get(API_URL + "listing/" + listId + "/coupon/create", { headers: authBizHeader() });
}

const getOwnerCoupons = () => {
   return http.get(API_URL + "coupons", { headers: authBizHeader() });
}

const storeDiscountCoupon = (formData) => {
   return http.post(API_URL + "coupon", formData, { headers: authBizHeader() });
}

const getCouponConfirmation = (couponId) => {
   return http.get(API_URL + "coupon/" + couponId + "/confirmation", { headers: authBizHeader() });
}

const updateDiscountCoupon = (formData, couponId) => {
   return http.post(API_URL + "coupon/" + couponId, formData, { headers: authBizHeader() });
}

const getProductCreateData = () => {
   return http.get(API_URL + "product-data", { headers: authBizHeader() });   
}

const getProducts = (page) => {
   return http.get(API_URL + "products", 
      { 
         headers: authBizHeader(),
         params: {
            page: page
         }, 
      }
   );
}

const storeProduct = (formData) => {
   return http.post(API_URL + "product", formData, { headers: authBizHeader() });
}

const editProduct = (productId) => {
   return http.get(API_URL + "product/" + productId + "/edit", { headers: authBizHeader() });
}

const updateProduct = (formData, productId) => {
   return http.post(API_URL + "product/" + productId, formData, { headers: authBizHeader() });
}

const updateProductStatus = (formData, productId) => {
   return http.post(API_URL + "product/" + productId + "/status", formData, { headers: authBizHeader() });
}

const deleteProduct = (productId) => {
   return http.delete(API_URL + "product/" + productId, { headers: authBizHeader() });
}

const getMyOrders = () => {
   return http.get(API_URL + "orders", { headers: authBizHeader() });
}

const getOrderDetails = (orderId) => {
   return http.get(API_URL + "order/" + orderId + "/details", { headers: authBizHeader() });
}

const getInbox = () => {
   return http.get(API_URL + "inbox", { headers: authBizHeader() });
}

const getInboxOwnerMessages = (convId, participantId) => {
   return http.get(API_URL + "inbox/" + convId + "/messages/" + participantId, 
         { 
            headers: authBizHeader() 
         }
      );
}

const loadMoreOwnerMessages = (convId, participantId, currentPage) => {
   return http.get(API_URL + "inbox/" + convId + "/messages/" + participantId, 
       { 
           headers: authBizHeader(),
           params: { 
               page: currentPage + 1,
           },
       }
   );
}

const storeInboxOwnerReply = (formData) => {
   return http.post(API_URL + "inbox/reply", formData, { headers: authBizHeader() });
}

const getOwnerProfile = () => {
   return http.get(API_URL + "profile", { headers: authBizHeader() });
}

const updateOwnerProfile = (formData, ownerId) => {
   return http.post(API_URL + "profile/" + ownerId, formData, { headers: authBizHeader() });
}

const updateProfileImage = (formData) => {
   return http.post(API_URL + "profile-image", formData, { headers: authBizHeader() });
}

const changePassword = (formData) => {
   return http.post(API_URL + "password", formData, { headers: authBizHeader() });
}

const ownerService = {
   getOwnerHeaderData,
   getNotifications,
   markNotificationsAllRead,
   getDashboardData,
   getListingChartData,
   getListings,
   getClaims,
   updateListingStatus,
   getCampaignCreateData,
   getOwnerListingsForAdCampaign,
   getListingReviews,
   getReview,
   storeReviewThanksReply,
   storeReviewOtherReply,
   storeReviewReport,
   getListingLogo,
   uploadListingLogo,
   deleteListingLogo,
   searchListing,
   getClaimByListingId,
   storeClaim,
   storeListing,
   getListingConfirmation,
   getListingEdit,
   updateListingBasicInfo,
   updateListingCategory,
   updateListingFeatures,
   updateListingOfficeHours,
   updateListingPromoVideo,
   updateListingSpecialties,
   updateListingDescription,
   updateListingHistory,
   updateListingAddress,
   updateListingSocialLinks,
   updateListingEventsTimming,
   deleteSocialMediaLink,
   getListingCoverImages,
   uploadListingCoverImages,
   deleteListingCoverImage,
   getListingGallery,
   storeListingGalleryPhotos,
   reportGalleryPhoto,
   getListingStats,
   getOwnerCampaigns,
   storeAdCampaign,
   getCampaignEdit,
   updateAdCampaign,
   getCampaignForPreview,
   getCampaignConfirmation,
   deleteCampaign,
   ListingDiscountCouponCreate,
   getOwnerCoupons,
   storeDiscountCoupon,
   getCouponConfirmation,
   updateDiscountCoupon,
   getProductCreateData,
   getProducts,
   storeProduct,
   editProduct,
   updateProduct,
   updateProductStatus,
   deleteProduct,
   getMyOrders,
   getOrderDetails,
   getInbox,
   getInboxOwnerMessages,
   loadMoreOwnerMessages,
   storeInboxOwnerReply,
   getOwnerProfile,
   updateOwnerProfile,
   updateProfileImage,
   changePassword
};

export default ownerService;