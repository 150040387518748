import http from "../lib/http-common";
import authHeader from "./authHeader";
import authBizHeader from "./authBizHeader";

let API_URL = process.env.REACT_APP_BACKEND_URL + "/api/v2/";

//----- User Auth --------------------------------------------------------------------------

const register = (formData) => {
    return http
        .post(API_URL + "register", formData)
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("token", JSON.stringify(response.data.accessToken));
            }

            return response;
        });
};

const login = (formData) => {
    return http
        .post(API_URL + "login", formData)
        .then((response) => {
            if (response.data.accessToken) {
            //  sessionStorage.setItem("logged", true);
                localStorage.setItem("token", JSON.stringify(response.data.accessToken));
            }

            return response;
        });
};

const userSocialLogin = (formData) => {
    return http
        .post(API_URL + "login-social", formData)
        .then((response) => {
            if(response.data.accessToken) {
                localStorage.setItem("token", JSON.stringify(response.data.accessToken));
            }

            return response;
        });
};

const verifyEmail = (id, hash) => {
    return http.get(API_URL + `email/verify/${id}/${hash}`, {
        headers: authHeader(),
    });
};

const verifyCode = (formData) => {
    return http.post(API_URL + "email/code/verify", formData, { headers: authHeader() });
};

const resendVerificationEmail = () => {
    return http.get(API_URL + "email/resend", { headers: authHeader() });
};

const resendVerificationCode = () => {
    return http.get(API_URL + "email/code/resend", { headers: authHeader() });
};

const sendResetLinkEmail = (formData) => {
    return http.post(API_URL + "password/email", formData);
};

const resetPasswordLink = (formData) => {
    return http.post(API_URL + "password/reset", formData);
};

const logout = () => {
    return http
        .get(API_URL + "logout", { headers: authHeader() })
        .then((response) => {
            if (response.data.success) {
                localStorage.removeItem("token");
                window.location.replace("/login");
                //  window.location.reload();
            }
        });
};

//----- Business Auth --------------------------------------------------------------------------

const registerOwner = (formData) => {
    return http
        .post(API_URL + "biz/register", formData)
        .then((response) => 
        {
            if (response.data.accessToken) {
                localStorage.setItem("token_biz", JSON.stringify(response.data.accessToken));
            }

            return response;
        });
};

const loginOwner = (formData) => {
    return http
        .post(API_URL + "biz/login", formData)
        .then((response) => 
        {
            if (response.data.accessToken) {
            //  sessionStorage.setItem("logged", true);
                localStorage.setItem("token_biz", JSON.stringify(response.data.accessToken));
            }

            return response;
        });
}

const sendResetLinkEmailOwner = (formData) => {
    return http.post(API_URL + "biz/password/email", formData);
}

const resetPasswordLinkOwner = (formData) => {
    return http.post(API_URL + "biz/password/reset", formData);
}

const verifyEmailCodeOwner = (formData) => {
    return http.post(API_URL + `biz/email/verify`, formData, { headers: authBizHeader() });
};

const resendEmailCodeOwner = () => {
    return http.get(API_URL + `biz/email/resend`, { headers: authBizHeader() });
}

const logoutBiz = () => {
    return http
        .get(API_URL + "biz/logout", { headers: authBizHeader() })
        .then((response) => {
            if (response.data.success) {
                localStorage.removeItem("token_biz");
                window.location.replace("/biz/login");
                //  window.location.reload();
            }
        });
};

const authService = {
    register,
    login,
    userSocialLogin,
    verifyEmail,
    verifyCode,
    resendVerificationEmail,
    resendVerificationCode,
    sendResetLinkEmail,
    resetPasswordLink,
    logout,
    registerOwner,
    loginOwner,
    sendResetLinkEmailOwner,
    resetPasswordLinkOwner,
    verifyEmailCodeOwner,
    resendEmailCodeOwner,
    logoutBiz
};

export default authService;