import http from "../lib/http-common";
import authHeader from "./authHeader";

let API_URL = process.env.REACT_APP_BACKEND_URL + "/api/v2/user/";

const getUserHeaderData = () => {
    return http.get(API_URL + "header-data", { headers: authHeader() });
};

const getNotifications = (page, platform) => {
    return http.get(API_URL + "notifications/" + platform, 
        { 
            headers: authHeader(),
            params: {
                page: page + 1
            },
        }
    );
}

const markNotificationsAllRead = () => {
    return http.get(API_URL + "notification/read", { headers: authHeader() });
}

const getDashboardData = (page) => {
    return http.get(API_URL + "dashboard", { headers: authHeader() });
}

const getFriendsList = () => {
    return http.get(API_URL + "friends", { headers: authHeader() });
}

const getReviewsList = (page) => {
    return http.get(API_URL + "reviews", 
        { 
            headers: authHeader(),
            params: {
                page: page + 1
            }, 
        }
    );
}

const storeReview = (formData) => {
    return http.post(API_URL + "review", formData, { headers: authHeader() });
}

const getReview = (reviewId) => {
    return http.get(API_URL + "review/" + reviewId, { headers: authHeader() });
}

const deleteReview = (reviewId) => {
    return http.delete(API_URL + "review/" + reviewId, { headers: authHeader() });
}

const storeReviewVote = (formData) => {
    return http.post(API_URL + "vote", formData, { headers: authHeader() });
}

const storeReviewReport = (formData) => {
    return http.post(API_URL + "review-report", formData, { headers: authHeader() });
}

const storeListingGalleryPhotos = (formData) => {
    return http.post(API_URL + "listing/gallery", formData, { headers: authHeader() });
}

const getBookmarkList = (page) => {
    return http.get(API_URL + "bookmarks", 
        { 
            headers: authHeader(),
            params: { 
                page: page,
            },
        }
    );
}

const checkListingBookmarkStatus = (listingId) => {
    return http.get(API_URL + "bookmark/" + listingId + "/status", { headers: authHeader() });
}

const processListingBookmark = (formData) => {
    return http.post(API_URL + "bookmark", formData, { headers: authHeader() });
}

const getFollowingsList = (page) => {
    return http.get(API_URL + "followings", 
        { 
            headers: authHeader(),
            params: {
                page: page + 1
            }
        }
    );
}

const checkFollowingStatus = (profileId) => {
    return http.get(API_URL + "following-status/" + profileId, { headers: authHeader() });
}

const addFollowing = (formData) => {
    return http.post(API_URL + "following", formData, { headers: authHeader() });
}

const getFollowersList = (page) => {
    return http.get(API_URL + "followers", 
        { 
            headers: authHeader(),
            params: {
                page: page + 1
            }
        }
    );
}

const getInbox = (page) => {
    return http.get(API_URL + "inbox", 
        { 
            headers: authHeader(),
            page: page + 1
        }
    );
}

const getInboxMessagesByUsername = (username, userType) => {
    return http.get(API_URL + "inbox/" + username + "/conversation/" + userType, 
        {
            headers: authHeader()
        }
    );
}

const getInboxUserMessages = (convId, participantId, isOwner) => {
    return http.get(API_URL + "inbox/" + convId + "/messages/" + participantId + "/" + isOwner, 
        { 
            headers: authHeader(),
        }
    );
}

const loadMoreUserMessages = (convId, participantId, isOwner, currentPage) => {
    return http.get(API_URL + "inbox/" + convId + "/messages/" + participantId + "/" + isOwner, 
        { 
            headers: authHeader(),
            params: { 
                page: currentPage + 1,
            },
        }
    );
}

const storeInboxUserReply = (formData) => {
    return http.post(API_URL + "inbox/reply", formData, { headers: authHeader() });   
}

const sendMessageToUser = (formData) => {
    return http.post(API_URL + "inbox/message/user", formData, { headers: authHeader() });
}

const sendMessageToListingOwner = (formData) => {
    return http.post(API_URL + "inbox/message/listing", formData, { headers: authHeader() });
}

const searchListings = (formData) => {
    return http.post(API_URL + "listings/search", formData, { headers: authHeader() });   
}

const addListing = (formData) => {
    return http.post(API_URL + "listing", formData, { headers: authHeader() });   
}

const getListingConfirmation = (listId) => {
    return http.get(API_URL + "listing/" + listId + "/confirmation", { headers: authHeader() });   
}

const getRecommendedListings = (page) => {
    return http.get(API_URL + "listings", 
        { 
            headers: authHeader(),
            params: { 
                page: page,
            },
        }
    );
}

const getFriendRequests = () => {
    return http.get(API_URL + "friend-requests", { headers: authHeader() });
}

const processFriendRequest = (formData) => {
    return http.post(API_URL + "friend-request/process", formData, { headers: authHeader() });
}

const checkFriendStatus = (profileId) => {
    return http.get(API_URL + "friend-status/" + profileId, { headers: authHeader() });
}

const addFriend = (formData) => {
    return http.post(API_URL + "friend", formData, { headers: authHeader() });
}

const getNotFollowingUsers = () => {
    return http.get(API_URL + "not-following-users", { headers: authHeader() });
}

const searchFriends = (formData) => {
    return http.post(API_URL + "friends/search", formData, { headers: authHeader() });   
}

const getUserActivities = (page) => {
    return http.get(API_URL + "activities", 
        { 
            headers: authHeader(),
            params: { 
                page: page + 1,
            },
        }
    );
}

const getRecentActivities = (page) => {
    return http.get(API_URL + "recent-activities", 
        { 
            headers: authHeader(),
            params: { 
                page: page + 1,
            },
        }
    );
}

const getUserFeed = (page) => {
    return http.get(API_URL + "feed", 
        { 
            headers: authHeader(),
            params: { 
                page: page + 1,
            },
        }
    );
}

const getUserProfile = () => {
    return http.get(API_URL + "profile", { headers: authHeader() });
}

const updateUserProfile = (formData, userId) => {
    return http.post(API_URL + "profile/" + userId, formData, { headers: authHeader() });
}

const updateProfileImage = (formData) => {
    return http.post(API_URL + "profile-image", formData, { headers: authHeader() });
}

const updateCoverImage = (formData) => {
    return http.post(API_URL + "cover-image", formData, { headers: authHeader() });
}

const storePost = (formData) => {
    return http.post(API_URL + "post", formData, { headers: authHeader() });
}

const getPost = (postId) => {
    return http.get(API_URL + "post/" + postId, { headers: authHeader() });
}

const storePostVote = (formData) => {
    return http.post(API_URL + "post-vote", formData, { headers: authHeader() });
}

const deletePost = (postId) => {
    return http.delete(API_URL + "post/" + postId, { headers: authHeader() });
}

const storePostReport = (formData) => {
    return http.post(API_URL + "post-report", formData, { headers: authHeader() });
}

const blockProfile = (formData) => {
    return http.post(API_URL + "block", formData, { headers: authHeader() });
}

const profileReport = (formData) => {
    return http.post(API_URL + "report", formData, { headers: authHeader() });
}

const closeAccount = () => {
    return http.get(API_URL + "close", { headers: authHeader() });
}

const deactivateAccount = () => {
    return http.get(API_URL + "deactivate", { headers: authHeader() });
}

const changePassword = (formData) => {
    return http.post(API_URL + "password", formData, { headers: authHeader() });
}

const updateUserSettings = (formData) => {
    return http.post(API_URL + "settings", formData, { headers: authHeader() });    
}

const userService = {
    getUserHeaderData,
    getNotifications,
    markNotificationsAllRead,
    getDashboardData,
    getFriendsList,
    getReviewsList,
    storeReview,
    getReview,
    deleteReview,
    storeReviewVote,
    storeReviewReport,
    storeListingGalleryPhotos,
    getBookmarkList,
    checkListingBookmarkStatus,
    processListingBookmark,
    getFollowingsList,
    checkFollowingStatus,
    addFollowing,
    getFollowersList,
    getInbox,
    getInboxMessagesByUsername,
    getInboxUserMessages,
    loadMoreUserMessages,
    storeInboxUserReply,
    sendMessageToUser,
    sendMessageToListingOwner,
    searchListings,
    addListing,
    getListingConfirmation,
    getRecommendedListings,
    getFriendRequests,
    processFriendRequest,
    checkFriendStatus,
    addFriend,
    getNotFollowingUsers,
    searchFriends,
    getUserActivities,
    getRecentActivities,
    getUserFeed,
    getUserProfile,
    updateUserProfile,
    updateProfileImage,
    updateCoverImage,
    storePost,
    getPost,
    storePostVote,
    deletePost,
    storePostReport,
    blockProfile,
    profileReport,
    closeAccount,
    deactivateAccount,
    changePassword,
    updateUserSettings
};

export default userService;