import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import ownerService from "../../services/ownerService";

export const getOwnerDetails = createAsyncThunk(
    "owner/getOwnerDetails",
    async (thunkAPI) => {
        try {
            const response = await ownerService.getOwnerHeaderData();
            return response.data.data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return thunkAPI.rejectWithValue(error.response.data.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const markOwnerNotificationAllRead = createAsyncThunk(
    "owner/markOwnerNotificationAllRead",
    async () => {
        const response = await ownerService.markNotificationsAllRead();
        return response.data.data;
    }
);

const initialState = {
    loading: false,
    owner: null,
    notifications: '', 
    notificationDescription: '', 
    unreadNotifications: '', 
    unreadMsgs: ''
};

const ownerSlice = createSlice({
    name: "owner",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getOwnerDetails.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getOwnerDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.owner = action.payload.owner;
            state.notifications = action.payload.notifications;
            state.notificationDescription = action.payload.notificationDescriptions;
            state.unreadNotifications = action.payload.totalUnReadNotifications;
            state.unreadMsgs = action.payload.unreadMsgs;
        });
        builder.addCase(getOwnerDetails.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(markOwnerNotificationAllRead.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(markOwnerNotificationAllRead.fulfilled, (state, action) => {
            state.loading = false;
            state.notifications = action.payload.notifications;
            state.notificationDescription = action.payload.notificationDescriptions;
            state.unreadNotifications = action.payload.totalUnReadNotifications;
        });
        builder.addCase(markOwnerNotificationAllRead.rejected, (state, action) => {
            state.loading = false;
        });
    }
});

const { reducer } = ownerSlice;
export default reducer;