import React, { useState, useEffect, Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import LottieLoader from "./components/loaders/LottieLoader";
import "./App.css";

const Home = lazy(() => import("./pages/Guest/HomePage"));
const ListingDetails = lazy(() => import("./pages/Guest/ListingDetails"));
const ReviewPreview = lazy(() => import("./pages/Guest/ReviewPreview"));
const PostPreview = lazy(() => import("./pages/Guest/PostPreview"));
// const ListingDetailsNew = lazy(() => import("./pages/Guest/ListingDetailsNew"));
const ReviewConfirmation = lazy(() => import("./pages/Guest/ReviewConfirmation"));
const ListingGallery = lazy(() => import("./pages/Guest/ListingGallery"));
const City = lazy(() => import("./pages/Guest/City"));
const Category = lazy(() => import("./pages/Guest/Category"));
const Login = lazy(() => import("./pages/User/Auth/Login"));
const Register = lazy(() => import("./pages/User/Auth/Register"));
// const VerifyEmail = lazy(() => import("./pages/User/Auth/VerifyEmail"));
const VerifyCode = lazy(() => import("./pages/User/Auth/VerifyCode"));
const ForgotPassword = lazy(() => import("./pages/User/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/User/Auth/ResetPassword"));
const Search = lazy(() => import("./pages/Guest/Search"));
const WriteReview = lazy(() => import("./pages/Guest/WriteReview"));
const People = lazy(() => import("./pages/Guest/People"));
const Events = lazy(() => import("./pages/Guest/Events"));
const Blogs = lazy(() => import("./pages/Guest/Blogs"));
const BlogDetails = lazy(() => import("./pages/Guest/BlogDetails"));
const PrivacyPolicy = lazy(() => import('./pages/Guest/PrivacyPolicy'));
const UserTermConditions = lazy(() => import('./pages/Guest/UserTermConditions'));
const BusinessTermConditions = lazy(() => import('./pages/Guest/BusinessTermConditions'));
// const Pages = lazy(() => import("./pages/Guest/Pages"));
const AboutPage = lazy(() => import("./pages/Guest/AboutPage"));
const SocialNetworkPage = lazy(() => import("./pages/Guest/SocialNetworkPage"));
const MembershipPage = lazy(() => import("./pages/Guest/MembershipPage"));
const DiscountCouponPage = lazy(() => import("./pages/Guest/DiscountCouponPage"));
const NotFound = lazy(() => import("./pages/Guest/NotFound"));
const DashboardHome = lazy(() => import("./pages/User/Dashboard/Home"));
const DashboardFriends = lazy(() => import("./pages/User/Dashboard/Friends"));
const DashboardReviews = lazy(() => import("./pages/User/Dashboard/Reviews"));
const DashboardReviewDeleteConfirmation = lazy(() => import("./pages/User/Dashboard/ReviewDeleteConfirmation"));
const DashboardBookmarks = lazy(() => import("./pages/User/Dashboard/Bookmarks"));
const DashboardFollowings = lazy(() => import("./pages/User/Dashboard/Followings"));
const DashboardFollowers = lazy(() => import("./pages/User/Dashboard/Followers"));
const DashboardInbox = lazy(() => import("./pages/User/Dashboard/Inbox"));
const DashboardListingCreate = lazy(() => import("./pages/User/Dashboard/ListingCreate"));
const DashboardListingConfirmation = lazy(() => import("./pages/User/Dashboard/ListingConfirmation"));
const DashboardRecommendedListings = lazy(() => import("./pages/User/Dashboard/RecommendedListings"));
const DashboardFriendshipRequests = lazy(() => import("./pages/User/Dashboard/FriendshipRequests"));
const DashboardActivities = lazy(() => import("./pages/User/Dashboard/Activities"));
const DashboardFeed = lazy(() => import("./pages/User/Dashboard/Feed"));
const DashboardProfile = lazy(() => import("./pages/User/Dashboard/Profile"));
const DashboardSearch = lazy(() => import("./pages/User/Dashboard/Search"));
const DashboardPassword = lazy(() => import("./pages/User/Dashboard/Password"));
const DashboardAccountClose = lazy(() => import("./pages/User/Dashboard/Close"));
const DashboardSettings = lazy(() => import("./pages/User/Dashboard/Settings"));
const DashboardPostCreate = lazy(() => import("./pages/User/Dashboard/PostCreate"));
const DashboardPostConfirmation = lazy(() => import("./pages/User/Dashboard/PostConfirmation"));

const BizLogin = lazy(() => import("./pages/Owner/Auth/Login"));
const BizVerifyEmail = lazy(() => import("./pages/Owner/Auth/VerifyEmail"));
const BizRegister = lazy(() => import("./pages/Owner/Auth/Register"));
const BizForgotPassword = lazy(() => import("./pages/Owner/Auth/ForgotPassword"));
const BizResetPassword = lazy(() => import("./pages/Owner/Auth/ResetPassword"));

const BizHome = lazy(() => import("./pages/Owner/HomePage"));
// const BizClaim = lazy(() => import("./pages/Owner/Claim"));
const BizSupport = lazy(() => import("./pages/Owner/Support"));
const BizSupportConfirm = lazy(() => import("./pages/Owner/SupportConfirm"));
const BizDashboardHome = lazy(() => import("./pages/Owner/Dashboard/Home"));
const BizDashboardListingReviews = lazy(() => import("./pages/Owner/Dashboard/ListingReviews"));
const BizDashboardReviewReply = lazy(() => import("./pages/Owner/Dashboard/ReviewReply"));
const BizDashboardListings = lazy(() => import("./pages/Owner/Dashboard/Listings"));
const BizDashboardListingSearch = lazy(() => import("./pages/Owner/Dashboard/ListingSearch"));
const BizDashboardListingCreate = lazy(() => import("./pages/Owner/Dashboard/ListingCreate"));
const BizDashboardListingConfirm = lazy(() => import("./pages/Owner/Dashboard/ListingConfirm"));
const BizDashboardEditListing = lazy(() => import("./pages/Owner/Dashboard/EditListing"));
const BizDashboardListingCoverImages = lazy(() => import("./pages/Owner/Dashboard/ListingCoverImages"));
const BizDashboardListingLogo = lazy(() => import("./pages/Owner/Dashboard/ListingLogo"));
const BizDashboardListingGallery = lazy(() => import("./pages/Owner/Dashboard/ListingGallery"));
const BizDashboardListingStats = lazy(() => import("./pages/Owner/Dashboard/ListingStats"));
const BizDashboardCampaigns = lazy(() => import("./pages/Owner/Dashboard/Campaigns"));
const BizDashboardCampaignCreate = lazy(() => import("./pages/Owner/Dashboard/CampaignCreate"));
const BizDashboardCampaignConfirm = lazy(() => import("./pages/Owner/Dashboard/CampaignConfirm"));
const BizDashboardCampaignEdit = lazy(() => import("./pages/Owner/Dashboard/CampaignEdit"));
const BizDashboardCampaignAdPreview = lazy(() => import("./pages/Owner/Dashboard/CampaignAdPreview"));
const BizDashboardCoupons = lazy(() => import("./pages/Owner/Dashboard/Coupons"));
const BizDashboardListingCouponCreate = lazy(() => import("./pages/Owner/Dashboard/ListingCouponCreate"));
const BizDashboardCouponConfirm = lazy(() => import("./pages/Owner/Dashboard/CouponConfirm"));
const BizDashboardReviews = lazy(() => import("./pages/Owner/Dashboard/Reviews"));
const BizDashboardProfile = lazy(() => import("./pages/Owner/Dashboard/Profile"));
const BizDashboardPassword = lazy(() => import("./pages/Owner/Dashboard/Password"));
const BizDashboardBilling = lazy(() => import("./pages/Owner/Dashboard/Billing"));
const BizDashboardBillingDetails = lazy(() => import("./pages/Owner/Dashboard/BillingDetails"));
const BizDashboardInbox = lazy(() => import("./pages/Owner/Dashboard/Inbox"));
const BizDashboardProducts = lazy(() => import("./pages/Owner/Dashboard/Products"));
const BizDashboardProductCreate = lazy(() => import("./pages/Owner/Dashboard/ProductCreate"));
const BizDashboardProductEdit = lazy(() => import("./pages/Owner/Dashboard/ProductEdit"));
// const BizDashboardHomeNew = lazy(() => import('./pages/Owner/Dashboard/HomeNew'));
// const BizDashboardEditListing = lazy(() => import("./pages/Owner/Dashboard/EditBusiness"));
// const BizDashboardDiscountCoupon = lazy(() => import("./pages/Owner/Dashboard/DiscountCoupons"));
const BizDashboardClaim = lazy(() => import("./pages/Owner/Dashboard/Claim"));
const BizDashboardClaimConfirm = lazy(() => import("./pages/Owner/Dashboard/ClaimConfirm"));

const UserHome = lazy(() => import("./pages/User/Profile/Home"));
const UserFriends = lazy(() => import("./pages/User/Profile/Friends"));
const UserReviews = lazy(() => import("./pages/User/Profile/Reviews"));
const UserPhotos = lazy(() => import("./pages/User/Profile/Photos"));
const UserFollowers = lazy(() => import("./pages/User/Profile/Followers"));
const UserFollowings = lazy(() => import("./pages/User/Profile/Followings"));
// const UserContact = lazy(() => import("./pages/User/Profile/Contact"));

function App() {

    const { user } = useSelector((state) => state.auth);
    const { owner } = useSelector((state) => state.authBiz);
	const [isEmailVerified, setEmailVerified] = useState(true);
    const [isOwnerEmailVerified, setOwnerEmailVerified] = useState(true);
	
	useEffect(() => {
        if(user) {
			if(user.email_verified_at == null) {
				setEmailVerified(false)
			} else {
				setEmailVerified(true)
			}
        }
    }, [user]);

    useEffect(() => {
        if(owner) {
			if(owner.email_verified_at == null) {
				setOwnerEmailVerified(false)
			} else {
				setOwnerEmailVerified(true)
			}
        }
    }, [owner]);

    return (
        <Suspense fallback={<LottieLoader />}>
            <Router>
                <Switch>
                    <Route 
                        exact 
                        path="/" 
                        component={Home} 
                    />
                    <Route
                        path="/listing/:slug"
                        component={ListingDetails}
                    />
                    <Route
                        path="/review/:reviewId/:slug"
                        component={ReviewPreview}
                    />
                    <Route
                        path="/review/:reviewId/confirmation"
                        component={ReviewConfirmation}
                    />
                    <Route
                        path="/post/:postId/preview"
                        component={PostPreview}
                    />
                    <Route
                        path="/gallery/:slug"
                        component={ListingGallery}
                    />
                    <Route exact path="/cities" component={City} />
                    <Route exact path="/categories" component={Category} />
                    <Route path="/search" component={Search} />
                    <Route
                        exact
                        path="/write-a-review"
                        component={WriteReview}
                    />
                    <Route
                        exact
                        path="/people"
                        component={People}
                    />
                    <Route
                        exact
                        path="/events"
                        component={Events}
                    />
                    <Route
                        exact
                        path="/blog"
                        component={Blogs}
                    />
                    <Route
                        path="/blog/:slug"
                        component={BlogDetails}
                    />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/verify-email" component={VerifyCode} /> 
                    <Route
                        exact
                        path="/forgot/password"
                        component={ForgotPassword}
                    />
                    <Route
                        exact
                        path="/reset-password"
                        component={ResetPassword}
                    />
                    <Route path="/profile/:slug/home" component={UserHome} />
                    <Route path="/profile/:slug/friends" component={UserFriends} />
                    <Route path="/profile/:slug/reviews" component={UserReviews} />
                    <Route path="/profile/:slug/photos" component={UserPhotos} />
                    <Route path="/profile/:slug/followers" component={UserFollowers} />
                    <Route path="/profile/:slug/followings" component={UserFollowings} />
                    {/*
                    <Route path="/contact/:slug" component={UserContact} />
                     */}
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/user-terms-of-service" component={UserTermConditions} />
                    <Route path="/business-terms-of-service" component={BusinessTermConditions} />
                    {/* <Route path="/pages/:slug" component={Pages} /> */}
                    <Route path="/about" component={AboutPage} />
                    <Route
                        path="/social-networking"
                        component={SocialNetworkPage}
                    />
                    <Route path="/membership" component={MembershipPage} />
                    <Route
                        path="/discount-coupons"
                        component={DiscountCouponPage}
                    />
                    <Route
                        exact
                        path="/user/dashboard"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardHome />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }} 
                    />   
                    <Route
                        exact
                        path="/user/friends"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardFriends />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/reviews"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardReviews />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/review/:reviewId/confirmation"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardReviewDeleteConfirmation />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/bookmarks"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardBookmarks />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/followings"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardFollowings />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/followers"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardFollowers />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/inbox/:username?/:usertype?"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardInbox />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/listing/create"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardListingCreate />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        path="/user/listing/:listId/confirmation"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardListingConfirmation />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/listings"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardRecommendedListings />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/friendship-requests"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardFriendshipRequests />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/feed"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardFeed />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/activities"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardActivities />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/profile"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardProfile />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/search"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardSearch />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/password"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardPassword />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/settings"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardSettings />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/close"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardAccountClose />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/user/post/create"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardPostCreate />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    <Route
                        path="/user/post/:postId/confirmation"
                        render={() => {
                            if (isEmailVerified) {
                                return <DashboardPostConfirmation />;
                            } else {
                                return <Redirect to={`/verify-email?email=${user.email}`} />;
                            }
                        }}
                    />
                    
                    <Route exact path="/biz/home" component={BizHome} />
                    <Route exact path="/biz/login" component={BizLogin} />
                    <Route exact path="/biz/verify-email" component={BizVerifyEmail} />
                    <Route exact path="/biz/register" component={BizRegister} />
                    <Route
                        exact
                        path="/biz/forgot/password"
                        component={BizForgotPassword}
                    />
                    <Route
                        exact
                        path="/biz/reset-password"
                        component={BizResetPassword}
                    />
                    <Route 
                        exact 
                        path="/biz/support" 
                        component={BizSupport} 
                    />
                    <Route
                        exact
                        path="/biz/support/:supportId/confirmation"
                        component={BizSupportConfirm}
                    /> 
                    <Route
                        exact
                        path="/biz/dashboard"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardHome />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/:listId/reviews"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardListingReviews />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        path="/biz/review/:reviewId/reply/:type"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardReviewReply />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listings"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardListings />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/search"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardListingSearch />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/create"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardListingCreate />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/:listId/confirmation"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardListingConfirm />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/:listId/edit"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardEditListing />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/:listId/cover-images"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardListingCoverImages />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/:listId/logo"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardListingLogo />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/:listId/gallery"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardListingGallery />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/:listId/stats"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardListingStats />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/campaign/create"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardCampaignCreate />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/campaign/:campId/confirmation"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardCampaignConfirm />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/campaign/:campId/edit"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardCampaignEdit />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/:listId/coupon/create"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardListingCouponCreate />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/coupons"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardCoupons />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/coupon/:couponId/confirmation"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardCouponConfirm />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/campaigns"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardCampaigns />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/campaign/ad/:campId/preview"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardCampaignAdPreview />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/reviews"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardReviews />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/products"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardProducts />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/product/create"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardProductCreate />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/product/:productId/edit"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardProductEdit />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/billing"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardBilling />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/billing/:orderId/details"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardBillingDetails />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/inbox"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardInbox />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/profile"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardProfile />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/password"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardPassword />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/listing/:listId/claim"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardClaim />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/biz/claim/:listId/confirmation"
                        render={() => {
                            if (isOwnerEmailVerified) {
                                return <BizDashboardClaimConfirm />;
                            } else {
                                return <Redirect to={`/biz/verify-email?email=${owner.email}`} />;
                            }
                        }}
                    /> 
                    <Route path="/error" component={NotFound} />
                    {/*    <Route component={NotFound} /> */}
                    <Redirect from="*" to="/error" />
                </Switch>
            </Router>
        </Suspense>
    );
}

export default App;
