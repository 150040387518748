import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setError } from "./errors";

import AuthService from "../../services/authService";
import ownerService from "../../services/ownerService";

const accessToken = JSON.parse(localStorage.getItem("token_biz"));

export const register = createAsyncThunk(
    "authBiz/register",
    async (formData, thunkAPI) => {
        try {
            const response = await AuthService.registerOwner(formData);
            thunkAPI.dispatch(setMessage(response.data.message));
            return response.data;
        } catch (error) {
            const message = error.response.data.message;
            if(message) {
                thunkAPI.dispatch(setMessage(message));    
            }
            const errorValidation = error.response.data.errors;
            if(errorValidation) {
                thunkAPI.dispatch(setError(errorValidation));
            }

            return thunkAPI.rejectWithValue();
        }
    }
);

export const login = createAsyncThunk(
    "authBiz/login",
    async (formData, thunkAPI) => {
        try {
            const response = await AuthService.loginOwner(formData);
            return { token: response.data.accessToken };
        } catch (error) {
            const message = error.response.data.message;
            if(message) {
                thunkAPI.dispatch(setMessage(message));    
            }
            const errorValidation = error.response.data.errors;
            if(errorValidation) {
                thunkAPI.dispatch(setError(errorValidation));
            }

            return thunkAPI.rejectWithValue();
        }
    }
);

export const verifyCode = createAsyncThunk(
    "authBiz/verifyCode",
    async (formData, thunkAPI) => {
        try {
            const response = await AuthService.verifyEmailCodeOwner(formData);
            return response.data;
        } catch (error) {
            const message = error.response.data.message;
            if(message) {
                thunkAPI.dispatch(setMessage(message));    
            }
            const errorValidation = error.response.data.errors;
            if(errorValidation) {
                thunkAPI.dispatch(setError(errorValidation));
            }

            return thunkAPI.rejectWithValue();
        }
    } 
);

export const logoutOwner = createAsyncThunk(
    "authBiz/logoutOwner", 
    async () => {
        await AuthService.logoutBiz();
    }
);

const initialState = {
    loading: false,
    isBizLoggedIn: accessToken ? true : false,
    token_biz: accessToken ? accessToken : null
};

const authBizSlice = createSlice({
    name: "authBiz",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(register.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(register.fulfilled, (state, action) => {
            state.loading = false;
            state.isBizLoggedIn = true;
            state.token_biz = action.payload.accessToken;
        });
        builder.addCase(register.rejected, (state, action) => {
            state.loading = false;
            state.isBizLoggedIn = false;
            state.token_biz = null;
        });
        builder.addCase(login.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
            state.isBizLoggedIn = true;
            state.token_biz = action.payload.accessToken;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.isBizLoggedIn = false;
            state.token_biz = null;
        });
        builder.addCase(verifyCode.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(verifyCode.fulfilled, (state, action) => {
            state.loading = false;
            state.owner = action.payload.owner;
        });
        builder.addCase(verifyCode.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(logoutOwner.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(logoutOwner.fulfilled, (state, action) => {
            state.loading = false;
            state.isBizLoggedIn = false;
            state.owner = null;
        });
    }
});

const { reducer } = authBizSlice;
export default reducer;