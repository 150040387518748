import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/auth';
import authBizReducer from './slices/authBiz';
import messageReducer from './slices/message';
import errorsReducer from './slices/errors';
import userReducer from './slices/user';
import ownerReducer from './slices/owner';
import chatReducer from './slices/chat';
import keywordsReducer from './slices/keywords';

const reducer = {
    auth: authReducer,
    authBiz: authBizReducer,
    message: messageReducer,     
    errors: errorsReducer,
    user: userReducer,
    owner: ownerReducer,
    chat: chatReducer,
    keywords: keywordsReducer
}

const store = configureStore({
    reducer: reducer,
    devTools: true
})

export default store;