import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
//import { PersistGate } from "redux-persist/integration/react";
import store from "./store";
import App from "./App";
import { SnackbarProvider } from "notistack";
// import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
            <App />
        </SnackbarProvider>
    </Provider>,
    document.getElementById("app")
);

// reportWebVitals();
