import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { setMessage } from "./message";
//import { setError } from "./errors";

import userService from "../../services/userService";

export const getUserDetails = createAsyncThunk(
    "user/getUserDetails",
    async (thunkAPI) => {
        try {
            const response = await userService.getUserHeaderData();
            return response.data.data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return thunkAPI.rejectWithValue(error.response.data.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const markUserNotificationAllRead = createAsyncThunk(
    "user/markUserNotificationAllRead",
    async () => {
        const response = await userService.markNotificationsAllRead();
        return response.data.data;
    }
);

export const getFriendRequests = createAsyncThunk(
    "user/getFriendRequests",
    async () => {
        const response = await userService.getFriendRequests();
        return response.data.data;
    }
);

export const getDashboard = createAsyncThunk(
    "user/getDashboard",
    async () => {
        const response = await userService.getDashboardData();
        return response.data.data;
    }
);

export const processFriendRequest = createAsyncThunk(
    "user/processFriendRequest",
    async (formData) => {
        const response = await userService.processFriendRequest(formData);
        return response.data.data;
    }
);

export const checkFriendStatus = createAsyncThunk(
    "user/checkFriendStatus",
    async (profileId) => {
        const response = await userService.checkFriendStatus(profileId);
        return response.data.data;
    }
);

export const addFriend = createAsyncThunk(
    "user/addFriend",
    async (formData) => {
        const response = await userService.addFriend(formData);
        return response.data.data
    }
);

export const checkFollowingStatus = createAsyncThunk(
    "user/checkFollowingStatus",
    async (profileId) => {
        const response = await userService.checkFollowingStatus(profileId);
        return response.data.data;
    }
);

export const addFollowing = createAsyncThunk(
    "user/addFollowing",
    async (formData) => {
        const response = await userService.addFollowing(formData);
        return response.data.data
    }
);

const initialState = {
    loading: false,
    friendStatus: 'empty',
    followingStatus: false,
    friendRequests: '',
    friendRequestsCount: 0,
    user: null,
    notifications: '',
    notificationDescription: '',
    unreadNotifications: '',
    unreadMsgs: '',
    ratingDist: '',
    stats: ''
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserDetails.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload.user;
            state.notifications = action.payload.notifications;
            state.notificationDescription = action.payload.notificationDescriptions;
            state.unreadNotifications = action.payload.totalUnReadNotifications;
            state.unreadMsgs = action.payload.unreadMsgs;
            state.ratingDist = action.payload.ratingDist;
            state.stats = action.payload.stats;
        });
        builder.addCase(getUserDetails.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(markUserNotificationAllRead.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(markUserNotificationAllRead.fulfilled, (state, action) => {
            state.loading = false;
            state.notifications = action.payload.notifications;
            state.notificationDescription = action.payload.notificationDescriptions;
            state.unreadNotifications = action.payload.totalUnReadNotifications;
        });
        builder.addCase(markUserNotificationAllRead.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getFriendRequests.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getFriendRequests.fulfilled, (state, action) => {
            state.loading = false;
            state.friendRequests = action.payload.requests;
            state.friendRequestsCount = action.payload.request_count;
        });
        builder.addCase(getFriendRequests.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getDashboard.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getDashboard.fulfilled, (state, action) => {
            state.loading = false;
            state.ratingDist = action.payload.ratingDist,
            state.stats = action.payload.stats;
            state.friendRequestsCount = action.payload.friendRequestsCount;
        });
        builder.addCase(getDashboard.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(processFriendRequest.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(processFriendRequest.fulfilled, (state, action) => {
            state.loading = false;
            state.friendRequests = action.payload.requests;
            state.friendRequestsCount = action.payload.request_count;
        });
        builder.addCase(processFriendRequest.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(checkFriendStatus.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(checkFriendStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.friendStatus = action.payload.status;
        });
        builder.addCase(checkFriendStatus.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(addFriend.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(addFriend.fulfilled, (state, action) => {
            state.loading = false;
            state.friendStatus = action.payload.status;
        });
        builder.addCase(addFriend.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(checkFollowingStatus.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(checkFollowingStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.followingStatus = action.payload.status;
        });
        builder.addCase(checkFollowingStatus.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(addFollowing.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(addFollowing.fulfilled, (state, action) => {
            state.loading = false;
            state.followingStatus = action.payload.status;
        });
        builder.addCase(addFollowing.rejected, (state, action) => {
            state.loading = false;
        });
    }
});

const { reducer } = userSlice;
export default reducer;